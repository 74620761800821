import React from 'react';
import get from 'lodash.get';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import * as styles from './ImageTile.module.scss';

const ChildrenPositionTypes = {
    'dead-center': 'justify-center items-center',
    'at-bottom': 'items-end',
    'bottom-right': 'justify-end items-end'
};

const GradientTypes = {
    'dead-center': 'img-bg-gradient',
    'to-top': 'img-bg-gradient--to-top',
};

const RatioTypes = {
    '1-to-1': styles.ImageTileSquare,
    '2-to-1': styles.ImageTileWidescreen,
    portrait: styles.ImageTilePortrait,
    none: styles.ImageTileFluid
};

export interface ImageTileProps {
    imageData: {
        id?: string,
        gatsbyImageData: IGatsbyImageData,
        description?: string,
    }
    children?: React.ReactNode,
    childrenPosition?: keyof typeof ChildrenPositionTypes;
    gradient?: keyof typeof GradientTypes;
    ratio?: keyof typeof RatioTypes;
}

const ImageTile = ({
    imageData,
    children,
    childrenPosition = 'bottom-right',
    gradient,
    ratio
}: ImageTileProps): JSX.Element => (
    <div className={`${styles.ImageTile} ${get(RatioTypes, ratio as string, '')}`}>
        <GatsbyImage image={imageData.gatsbyImageData} alt={(imageData.description) ? imageData.description : 'Alt text required'} className={styles.ImageTileAsset} />
        {
            (children)
                ? (
                    <div className={`${styles.ImageTileChildren} ${get(GradientTypes, gradient as string, '')} ${ChildrenPositionTypes[childrenPosition]}`}>
                        {children}
                    </div>
                )
                : null
        }
    </div>
);

export default ImageTile;
