import React from 'react';

import * as styles from './ImageGrid.module.scss';

import ImageTile, { ImageTileProps } from '../ImageTile/ImageTile';

const layoutTypes = {
    masonry: styles.ImageGridMasonry,
    oneOverTwo: styles.ImageGridOneOverTwo
};

export interface ImageGridProps {
    images: ImageTileProps['imageData'][],
    layout?: keyof typeof layoutTypes
}

const ImageGrid = ({ images, layout = 'masonry' }: ImageGridProps): JSX.Element => (
    <div className={`${styles.ImageGrid} ${layoutTypes[layout]}`}>
        {images.map((image) => (
            <ImageTile key={Math.random().toString().slice(2, 16)} imageData={image} />
        ))}
    </div>
);

export default ImageGrid;
