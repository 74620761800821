/* eslint-disable no-case-declarations */
import React, { Fragment } from 'react';
import { Link, graphql } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';

import { VimeoEntity } from '../../types/contentfulModels/VimeoEntity';

import IconArrowLeft from '../../components/Icons/IconArrowLeft';
import ImageTile, { ImageTileProps } from '../../components/ImageTile/ImageTile';
import ImageGrid from '../../components/ImageGrid/ImageGrid';
import ProjectCallout from '../../components/ProjectCallout/ProjectCallout';
import QuoteBlock from '../../components/QuoteBlock/QuoteBlock';
import RichTextBlock from '../../components/RichTextBlock/RichTextBlock';
import TagCloud from '../../components/TagCloud/TagCloud';
import Seo from '../../components/Seo/Seo';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import { QuoteBlockEntity } from '../../types/contentfulModels/QuoteBlockEntity';
import { GeneralRepeaterEntity } from '../../types/contentfulModels/GeneralRepeaterEntity';

interface ContentfulVimeo extends VimeoEntity, GeneralRepeaterEntity {
    internal: {
        type: 'ContentfulVimeo'
    }
}

interface ContentfulQuoteBlock extends QuoteBlockEntity, GeneralRepeaterEntity {
    internal: {
        type: 'ContentfulQuoteBlock'
    }
}

interface ContentfulRichTextBlock extends GeneralRepeaterEntity {
    content: RenderRichTextData<ContentfulRichTextGatsbyReference>
}

interface ContentfulImageGrid extends GeneralRepeaterEntity {
    images?: ImageTileProps['imageData'][],
    layout?: 'masonry'|'oneOverTwo',
}

interface SelectedProjectProps {
    data: {
        contentfulProject: {
            slug: string,
            title: string,
            heroImage: {
                gatsbyImageData: IGatsbyImageData
                description: string,
            }
            coverVideo?: VimeoEntity,
            tags?: string[]
            subtitle: string,
            description?: RenderRichTextData<ContentfulRichTextGatsbyReference>,
            projectBody: Array<any> // Array<ContentfulVimeo|ContentfulQuoteBlock|ContentfulRichTextBlock|ContentfulImageGrid>
        }
    }
}

const SelectedProject = ({ data }: SelectedProjectProps): JSX.Element => {
    const { contentfulProject: content } = data;

    console.log(data);

    return (
        <Fragment>
            <Seo title={`${content.title} | Projects`} />
            <div className="space-y-6 md:space-y-7">
                <header className="space-y-5 selected-project-hero">
                    <div className="contain">
                        <div className="gap-1 flex flex-col contain">
                            <Link to="/projects" className="space-x-1 valign--center">
                                <IconArrowLeft size="24px" stroke="white" />
                                <span className="text-smallcaps">Projects</span>
                            </Link>
                            <h1 className="text-title gold">{content.title}</h1>
                            <TagCloud tags={content.tags} />
                        </div>
                    </div>

                    <div className="contain containLarge">
                        {
                            (content.coverVideo)
                                ? (
                                    <VideoPlayer
                                        videoData={content.coverVideo}
                                    />
                                )
                                : null
                        }
                        {
                            (!content.coverVideo && content.heroImage)
                                ? (
                                    <ImageTile imageData={content.heroImage} ratio="2-to-1" />
                                )
                                : null
                        }
                    </div>
                </header>
                <div className="contain containMedium space-y-6 md:space-y-7">
                    {
                        (content.projectBody) ? content.projectBody.map((block) => {
                            if (block.internal.type === 'ContentfulImageGrid') {
                                const currentBlock = block as ContentfulImageGrid;

                                return (currentBlock.images && currentBlock.layout) ? (
                                    <ImageGrid
                                        layout={currentBlock.layout}
                                        images={currentBlock.images}
                                    />
                                ) : null;
                            }

                            if (block.internal.type === 'ContentfulQuoteBlock') {
                                const currentBlock = block as ContentfulQuoteBlock;
                                return (
                                    <div className="max-w-0.5 m-auto">
                                        <QuoteBlock author={currentBlock.author} quote={currentBlock.quote} />
                                    </div>
                                );
                            }

                            if (block.internal.type === 'ContentfulVimeo') {
                                return <VideoPlayer videoData={block as ContentfulVimeo} />;
                            }

                            if (block.internal.type === 'ContentfulRichTextBlock') {
                                const currentBlock = block as ContentfulRichTextBlock;

                                return (
                                    <div className="max-w-0.25 m-auto">
                                        <RichTextBlock content={currentBlock.content} />
                                    </div>
                                );
                            }

                            return null;
                        })
                            : null
                    }
                </div>
            </div>
            <ProjectCallout />
        </Fragment>
    );
};

export const pageQuery = graphql`
  query($id: String) {
    contentfulProject(id: { eq: $id }) {
      title
      slug
      tags
      heroImage {
          id
          gatsbyImageData(
                    width: 1920,
                    layout: FULL_WIDTH
                )
          description
      }
      description {
          raw
      }
      projectBody {
        ... on ContentfulRichTextBlock {
          id
          internal {
            type
          }
          content {
            raw
          }
        }
        ... on ContentfulImageGrid {
          id
          internal {
            type
          }
          images {
            id
            description
            gatsbyImageData(
                width: 1920
            )
          }
          layout
        }
      }
    }
  }
`;

export default SelectedProject;
