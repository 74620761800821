import React, { MouseEventHandler } from 'react';
import get from 'lodash.get';

import * as styles from './IconButton.module.scss';

const StyleTypes = {
    circle: styles.IconButtonCircle,
    none: ''
};

interface ButtonProps {
    onClick: MouseEventHandler<HTMLButtonElement>,
    children?: React.ReactNode,
    className?: string,
    label: string,
    buttonStyle?: keyof typeof StyleTypes
}

const IconButton = ({
    onClick, children, className, label, buttonStyle = 'circle'
}: ButtonProps): JSX.Element => (
    <button onClick={onClick} type="button" className={`${styles.IconButton} ${className} ${get(StyleTypes, buttonStyle, '')}`} title={label}>
        {children}
    </button>
);

export default IconButton;
