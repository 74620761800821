import React from 'react';
import * as styles from './TagCloud.module.scss';

import Tag from './Tag/Tag';

interface TagProps {
    tags?: string[]
}

const TagCloud = ({ tags }: TagProps): JSX.Element|null => {
    if (!tags) {
        return null;
    }

    return (
        <div className={styles.TagCloud}>
            {
                tags.map((tag) => (
                    <Tag key={tag} label={tag} />
                ))
            }
        </div>
    );
};

export default TagCloud;
