import React from 'react';

import * as styles from './Tag.module.scss';

interface TagProps {
    label: string
}

const Tag = ({ label }: TagProps): JSX.Element => (
    <span className={`text-label rounded ${styles.Tag}`}>{label}</span>
);

export default Tag;
