import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconPlay = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M1.07999 23.998C1.07999 30.098 3.50319 35.9481 7.81653 40.2615C12.1299 44.5748 17.98 46.998 24.08 46.998C30.18 46.998 36.0301 44.5748 40.3434 40.2615C44.6568 35.9481 47.08 30.098 47.08 23.998C47.08 17.898 44.6568 12.0479 40.3434 7.73454C36.0301 3.42121 30.18 0.998001 24.08 0.998001C17.98 0.998001 12.1299 3.42121 7.81653 7.73454C3.50319 12.0479 1.07999 17.898 1.07999 23.998V23.998Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.868 16.892C18.6852 16.7604 18.4675 16.6859 18.2424 16.6781C18.0173 16.6703 17.7949 16.7295 17.6035 16.8481C17.412 16.9668 17.2601 17.1395 17.1669 17.3446C17.0737 17.5497 17.0434 17.7777 17.08 18V32C17.0439 32.2221 17.0745 32.4499 17.1679 32.6546C17.2613 32.8593 17.4133 33.0317 17.6046 33.1501C17.796 33.2685 18.0181 33.3275 18.243 33.3196C18.4679 33.3118 18.6853 33.2374 18.868 33.106L33.292 25.894C34.274 25.402 34.274 24.598 33.292 24.106L18.868 16.892Z" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconPlay;
