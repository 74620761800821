import React from 'react';

import { QuoteBlockEntity } from '../../types/contentfulModels/QuoteBlockEntity';

import * as styles from './QuoteBlock.module.scss';

import IconQuote from '../Icons/IconQuote';

interface QuoteBlockProps extends QuoteBlockEntity{
    alignment?: 'text-left'|'text-center'
}

const QuoteBlock = ({ alignment = 'text-center', author, quote }: QuoteBlockProps): JSX.Element => (
    <div className={`${alignment} space-y-1`}>
        <blockquote className={`text-quote ${styles.QuoteBlock} ${(alignment === 'text-left') ? styles.QuoteBlockLeft : ''}`}><IconQuote fill="bronze-400" stroke="none" size="32px" className={styles.QuoteBlockIcon} />
            <p>{quote}</p>
        </blockquote>
        { (author) ? <cite className="inline-block text-smallcaps text-bronze-400">{author}</cite> : null }
    </div>
);

export default QuoteBlock;
