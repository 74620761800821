import React, { Fragment } from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconArrowLeft = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <Fragment>
            <path d="M11 34.994L1 24.994L11 14.994" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 24.994H47" strokeLinecap="round" strokeLinejoin="round" />
        </Fragment>
    </BaseIcon>
);

export default IconArrowLeft;
