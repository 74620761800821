import React from 'react';

import BaseIcon, { IconProps } from './BaseIcon';

const IconAnchor = (props: IconProps): JSX.Element => (
    <BaseIcon {...props}>
        <path d="M22.5528 8C8.32161 9.56784 0 18.2513 0 28.2613C0 34.8945 4.1005 39.7186 9.28643 39.7186C13.6281 39.7186 16.2814 36.8241 16.2814 32.9648C16.2814 26.2111 8.44221 27.1759 8.44221 21.7487C8.44221 17.0452 13.9899 13.4271 21.2261 12.3417L22.5528 8ZM48 8C33.7688 9.56784 25.4472 18.2513 25.4472 28.2613C25.4472 34.8945 29.5477 39.7186 34.7337 39.7186C39.0754 39.7186 41.7286 36.8241 41.7286 32.9648C41.7286 26.0904 33.8894 27.2965 33.8894 21.7487C33.8894 17.0452 39.4372 13.4271 46.6734 12.3417L48 8Z" />
    </BaseIcon>
);

export default IconAnchor;
