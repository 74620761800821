import React, { useState } from 'react';

import Vimeo from '@u-wave/react-vimeo';
import { Player } from '@vimeo/player';

import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from './VideoPlayer.module.scss';

import { VimeoEntity } from '../../types/contentfulModels/VimeoEntity';
import IconButton from '../Buttons/IconButton/IconButton';
import IconPlay from '../Icons/IconPlay';

export interface VideoPlayerProps {
    videoData: VimeoEntity,
}

const VideoPlayer = ({
    videoData,
}: VideoPlayerProps): JSX.Element => {
    const [currentPlayer, setCurrentPlayer] = useState<Player|null>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const playVideo = (): void => {
        if (currentPlayer) {
            currentPlayer.play();
            setIsPlaying(true);
        }
    };

    const resetCurrentPlayer = (): void => {
        setIsPlaying(false);
    };

    return (

        <div className={`${styles.VideoPlayer} `} style={{ paddingTop: `${(videoData.vimeoHeight / videoData.vimeoWidth) * 100}%` }}>
            <Vimeo
                video={videoData.vimeoId}
                showByline={false}
                showPortrait={false}
                showTitle={false}
                volume={0.4}
                autoplay={false}
                autopause={false}
                color="BE8963"
                onReady={setCurrentPlayer}
                onPlay={() => setIsPlaying(true)}
                onEnd={resetCurrentPlayer}
                className={styles.VideoPlayerEmbed}
            />
            {
                (videoData.vimeoThumbnail && !isPlaying)
                    ? <GatsbyImage image={videoData.vimeoThumbnail.gatsbyImageData} alt={(videoData.vimeoDescription) ? videoData.vimeoDescription : 'Alt text required'} className={styles.VideoPlayerThumbnail} />
                    : null
            }
            {
                (!isPlaying)
                    ? (
                        <IconButton label="Play video" onClick={playVideo} className={styles.VideoPlayerPlay}>
                            <IconPlay size="32px" />
                        </IconButton>
                    )
                    : null
            }
        </div>

    );
};

export default VideoPlayer;

